<template>
  <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" @close="closeDialog" :class="sessionsShow ? 'dialog-1005 ' : 'dialog-747'" append-to-body>
    <div class="dialog_body">
      <!-- 场次详情   start -->
      <div v-if="sessionsShow" id="session">
        <div class="back_btn_top_box">
          <el-button @click="sessionsShow = false" class="back_btn_top" type="primary" icon="el-icon-arrow-left">Back</el-button>
        </div>
        <div class="dialog_title mb10">
          <span>Attraction experience time {{ conversionTicketName(product.productId) }}</span>
        </div>
        <p class="tip">For time-designated attractions, please select a time when purchasing the pass.</p>
        <div class="notes">
          <div class="title">
            <img src="@/assets/warning.png" alt="">
            <span>Notes</span>
          </div>
          <!-- <p class="content">
              If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. When you are deciding when to participate, make sure the times do not overlap.
          </p>
          <p class="content">
              Availability may be limited depending on the entry date. Please check the availability calendar in advance.
          </p> -->
        </div>
        <sessionsTable :productid="productIdMap"></sessionsTable>
      </div>
      <!-- 场次详情  end -->
      <!-- 具体描述 start -->
      <div v-if="!sessionsShow">
        <div class="dialog_title mb10">
          <img src="@/assets/detail_head_icon.png" alt="">
          <span>Designated Attractions</span>
        </div>
        <div class="top_tip">
          <p>For time-designated attractions, please select a time when purchasing the pass.</p>
          <p>* Each attraction has certain requirements. Please check these requirements before purchasing a pass.</p>
        </div>
        <!-- 标记一下 -->
        <div class="top_tip" v-if="this.product.detailRequirement && this.product.websiteType!==4 || this.product.detailRequirement && this.product.websiteType!==5 || this.product.detailRequirement && this.product.websiteType!==8 ">
          <p v-if="product.detailRequirement != 'DonkeyKong'">
            {{ product.detailRequirement }}
          </p>
        </div>
        <div class="time_designated_box">
          <div v-for="(item, index) in product.productTicketWebsiteUsjList" :key="index" class="time_designated_item">
            <span class="label" v-show="item.isShowTag===1">Time-designated</span>
            <span class="label" v-show="item.isShowTag!==1"></span>
            <div>
              <p>{{ item.detailBatNo }} <span v-if="item.colorText" style="color: red;">{{ item.colorText }}</span> </p>
            </div>
          </div>
        </div>
        <div class="other_notes_box">
          <div class="notes_title">
            <img src="@/assets/warning.png" alt="">
            <span>Other Notes</span>
          </div>
          <div class="note_list">
            <div class="note_item">
              <p class="content">
                Before purchasing a Universal Express Pass, please check the
                <a target="_blank" href="https://www.usj.co.jp/web/en/us/tickets/express-pass#FrequentlyAskedQuestions">usage notes</a>
                and
                <a target="_blank" href="https://www.usj.co.jp/web/en/us/service-guide/safe">attraction requirements.</a>
              </p>
            </div>
            <div class="note_item">
              <p class="content">
                Attractions may be temporarily closed during certain periods.Please check our website in advance for information on
                <a target="_blank" href="https://www.usj.co.jp/web/en/us/park-guide/schedule/attraction-closure">temporary closures.</a>
              </p>
            </div>
            <div class="note_item">
              <p class="content">lf an attraction you selected is not in operation, you may use your pass on another attraction (does not include time-designated attractions).</p>
            </div>
            <div class="note_item">
              <p class="content">Attraction times and content may change without prior notice.</p>
            </div>
            <div class="note_item">
              <p class="content">Some attractions covered by the Express Pass may be considered separate attractions during special events. In such cases, you will not be able to use this pass for these attractions.</p>
            </div>
          </div>
          <!-- 这部分也是动态的，根据产品情况判断是否显示 start-->
          <div v-if="product.websiteType===1 || product.websiteType===4 || product.websiteType===5 || product.websiteType===8">
            <div class="notes_title">
              <img src="@/assets/warning.png" alt="">
              <span class="redFont">To enter Super Nintendo World™, guests need an Area Timed Entry Ticket or Area Timed Entry Ticket: Advance Booking.* This Express Pass includes an Area Timed EntryTicket: Advance Booking.</span>
            </div>
            <div v-if="product.detailRequirement == 'DonkeyKong'">
              <p>• Benefits are only valid on the day you use your Express Pass.</p>
              <p>• The Ride Photo Selection Package (3 photos) is not covered by this ticket.</p>
              <p style="margin-bottom: 20px;">• Please use your coupon for a single checkout totaling 2,200 yen or more per Express Pass. Change will not be given. </p>
            </div>
            <div>
              <p>lf you have not purchased an Area Timed Entry Ticket: Advance Booking or received one with a Universal Express Pass, you will need to get an Area Timed Entry Ticket through our official app on the day of your park visit.</p>
              <p class="mt10" style="margin-bottom: 20px;">* Depending on crowd conditions, you may be able to enter without an Area Timed Entry Ticket:Advance Booking or Area Timed Entry Ticket.</p>
            </div>
          </div>
          <!-- 这部分也是动态的，根据产品情况判断是否显示 end-->
        </div>
           <!-- 2 -->
           <div v-if="product.websiteType===4 || product.websiteType===5 || product.websiteType===6 || product.websiteType===7 || product.websiteType===8">
          
          <div class="dialog_title mt15">
          <span>Notes on Experiencing Horror Attractions</span>
        </div>
       
        <div>
          <div class="note_list mt15">
          <div class="note_item">
            <p class="content">While experiencing shows, please maintain a sufficient distance from other Guests. In addition, we ask that you refrain from facing other Guests and that all Guests face the same direction during the experience.</p>
          </div>
          <div class="note_item">
            <p class="content">We ask that you also maintain a sufficient distance from entertainers at all times, including when they are moving from place to place.</p>
          </div>
  
          <div class="note_item">
            <p class="content">Be extra cautious in the attraction as it has elements that may shock and startle Guests.</p>
          </div>
          <div class="note_item">
            <p class="content">Operation content and show content may be changed without prior notice.</p>
          </div>
          <div>
            <div class="boldFont mt15">Notes on Experiencing CHUCKY'S CARNIVAL OF CHAOS</div>
          <div class="note_item mt15">
            <p class="content">This attraction contains extreme content. Guests ages 14 or under will not be admitted.</p>
          </div>
          <div class="note_item">
            <p class="content">Those who are uncomfortable with grotesque imagery should not participate regardless of age.</p>
          </div>
          <div class="note_item">
            <p class="content">This attraction involves the use of 3D glasses. Depending on your location, objects may appear to pop out at you due to the 3D effect.</p>
          </div>
          <div class="note_item">
            <p class="content">Guests wearing glasses may find that the 3D glasses are uncomfortable due to the shape or size of their own glasses.</p>
          </div>
          </div>
          
          <div v-if="product.websiteType!==8">
            <div class="boldFont mt15">Notes on Experiencing Biohazard™: The Extreme + (Claire Route)</div>
          <div class="note_item mt15">
            <p class="content">This attraction contains graphic content. Guests ages 12 or under will not be admitted.</p>
          </div>
          <div class="note_item">
            <p class="content">Those who are uncomfortable with grotesque imagery should not participate regardless of age.</p>
          </div>
          <div class="note_item">
            <p class="content">This attraction includes the participant's use of a large, heavy machine gun. Unrestrained movements or swinging of the machine gun may cause you to hit other Guests. Please be careful when handling the machine gun.</p>
          </div>
          </div>
          <div v-if="product.websiteType!==4 &&  product.websiteType!==6 && product.websiteType!==8">
            <div class="boldFont mt15">Notes on Experiencing Sadako's Curse: Dark Horror Ride</div>
          <div class="note_item mt15">
            <p class="content">This attraction contains graphic content. Guests ages 5 or under will not be admitted. Guests ages 6 to 12 inclusive must be accompanied by an adult to experience the attraction.</p>
          </div>
          <div class="note_item">
            <p class="content">Those who are uncomfortable with horror-themed content should not experience the attraction, regardless of age.</p>
          </div>
          <div class="note_item">
            <p class="content">Guests must be at least 102 cm tall to ride this attraction. (Guests between 102 and 122 cm tall must be accompanied by a supervising Guest.)</p>
          </div>
          <div class="note_item">
            <p class="content">The following Guests should not experience this attraction: <br>Guests who are unable to sit upright unassisted or whose body size does not allow them to sit in the seats </p>
          </div>
       
          <div class="note_item">
            <p class="content">Bags and other items may not be brought onto the ride. Please store these items in a coin-operated locker.</p>
          </div>
          </div>
          <div v-if="  product.websiteType===8">
            <div class="boldFont mt15">Notes on Experiencing Biohazard™: The Extreme + (Leon Route)</div>
          <div class="note_item mt15">
            <p class="content">This attraction contains graphic content. Guests ages 12 or under will not be admitted.</p>
          </div>
          <div class="note_item">
            <p class="content">Those who are uncomfortable with grotesque imagery should not participate regardless of age.</p>
          </div>
          <div class="note_item">
            <p class="content">This attraction includes the participant's use of a large, heavy machine gun. Unrestrained movements or swinging of the machine gun may cause you to hit other Guests. Please be careful when handling the machine gun.</p>
          </div>
          </div>
          <div v-if=" product.websiteType!==6 && product.websiteType!==7">
            <div class="boldFont mt15">Notes on Experiencing Universal Monsters: Legends of Fear</div>
          <div class="note_item mt15">
            <p class="content">This attraction contains graphic content. Guests ages 12 or under will not be admitted.</p>
          </div>
          <div class="note_item">
            <p class="content">Those who are uncomfortable with grotesque imagery should not participate regardless of age.</p>
          </div>
          </div>
       
          </div>
          </div>
          <div>
     
        </div>
       </div>
        </div>
        <!-- 2 end -->
        <div class="dialog_title">
          <img src="@/assets/icon_clock.png" alt="">
          <span>Time-Designated Attractions</span>
        </div>
        <p class="boldFont Time-Designated_firstTxt">Entry times for time-designated attractions vary depending on where the tickets are sold.Please check the information in advance.</p>
        <!-- <p class="mt15">Select the time you would like to experience the time-designated attraction when purchasing your pass. Availability is limited.</p> -->
        <!-- <el-row class="btn_box">
          <div href="session" @click="viewSession()" class="select_designated_time_btn">
            View designated times
            <img src="@/assets/right.png" alt="">
          </div>
        </el-row> -->
        <div class="notes_title mt15">
          <img src="@/assets/warning.png" alt="">
          <span class="boldFont">Notes</span>
        </div>
        <div class="note_list">
          <div class="note_item">
            <p class="content">Attraction entry times and area entry times for time-designated attractions are printed on your ticket.</p>
          </div>
          <div class="note_item">
            <p class="content">Please purchase tickets for everyone in your group. If you purchase tickets separately, you may not be able to experience the attractions at the same time.</p>
          </div>
          <div class="note_item">
            <p class="content">If you have obtained a separate Area Timed Entry Ticket, you may enter the area at the time designated on the ticked.</p>
          </div>
          <div class="note_item">
            <p class="content">Refunds or time changes are not offered to those who arrive late to the area or attraction.</p>
          </div>
          <div class="note_item">
            <p class="content">lf you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. When you are deciding when to participate, make sure the times do not overlap.</p>
          </div>
        </div>
     
        <!-- 小地图部分satrt -->
        <!-- <div v-if="product.websiteType===1" style="margin-top: 18px;">
          <div class="dialog_title">
            <img src="@/assets/gift.png" alt="">
            <span>Power-Up Band Exchange Ticket</span>
          </div>
          <p class="boldFont blueFont mt10 mb10">Equip yourself with a Power-Up Band for even more fun!</p>
          <p>Unlock adventures in Super Nintendo World with a Power-Up Band!</p>
          <p>The Power-Up Band brings the experience of playing Super Mario into the real world! Use it to play key challenges, collect coins, and get digital stamps! Both kids and adults alike can unleash their passion to play in an immersive world of interactivity.</p>
          <p>* Link with your smartphone to see your score, coins, and digital stamps.</p>
          <p>* A smartphone is not required to enjoy the area. Check gameplay details and scores at checkpoints within the area.</p>
          <p>* A Power-Up Band can be purchased together with a Universal Express Pass that includes an Area Timed Entry Ticket: Advance Booking through the Web Ticketing Store.</p>
          <div class="map_box">
            <p class="boldFont blueFont">How to Purchase</p>
            <p class="mt10">Simply add a Universal Express Pass including a Super Nintendo World™ Timed Entry Ticket to your cart and you will see a button to purchase a Power-Up Band. </p>
            <div class="map_img_box mt10">
              <div class="mr15">
                <p class="boldFont blueFont">Price</p>
                <p>3,455 yen (3,800 yen with tax)</p>
                <p class="boldFont blueFont mt15">Pickup Location</p>
                <p>Studio Gifts West</p>
              </div>
              <img class="mr15" src="@/assets/detail_map.png" alt="">
            </div>
          </div>
          <div class="notes_title">
            <img src="@/assets/warning.png" alt="">
            <span>Notes</span>
          </div>
          <div class="note_list">
            <div class="note_item">
              <p class="content">Power-Up Bands cannot be purchased by themselves on the Web Ticketing Store.</p>
            </div>
            <div class="note_item">
              <p class="content">Guests who enter Super Nintendo World™ may purchase them at the park on the day of their visit.</p>
            </div>
            <div class="note_item">
              <p class="content">Pre-purchased Power-up Bands are available for pickup on the day of your park visit.</p>
            </div>
            <div class="note_item">
              <p class="content">Please exchange your ticket for a Power-up Band before entering Super Nintendo World™.</p>
            </div>
          </div>
        </div> -->
        <!-- 小地图部分end -->
        <div class="notes_title">
          <img src="@/assets/warning.png" alt="">
          <span>Notes</span>
        </div>
        <div class="note_list">
          <!-- <div class="note_item">
            <p class="content">You must wear a face mask while in the waiting line and while experiencing the attraction.Guests who choose not to wear a face mask will not be able to enter or experience the attraction.</p>
          </div>
          <div class="note_item">
            <p class="content">For more information about mask requirements, please ask a crew member.</p>
          </div> -->
          <!-- <div class="note_item"  v-if="product.websiteType!==1 && product.websiteType!==2">
            <p class="content">This ticket cannot be purchased at the same time as Power-Up Band Exchange Tickets.</p>
          </div> -->
          <div class="note_item">
            <p class="content">Please read the guidelines for the Universal Express Pass before your park visit.</p>
          </div>
          <div class="note_item">
            <p class="content">Once purchased, tickets cannot be modified, canceled, or changed.</p>
          </div>
          <div class="note_item">
            <p class="content">Prices and content may be changed without notice.</p>
          </div>
          <div class="note_item">
            <p class="content">Ticket availability is limited.</p>
          </div>
          <!-- <div class="note_item" v-if="product.websiteType!==1 && product.websiteType!==2">
            <p class="content">Up to 10 tickets can be purchased at a time.</p>
          </div> -->
          <div class="note_item">
            <p class="content">To enter the park, a separate 1 Day studio Pass, Annual Pass, or other Studio Pass (entry ticket) is required.A studio Pass is required if entering the park on days excluded from your Annual Pass.</p>
          </div>
          <div class="note_item">
            <p class="content">Prices may vary for each entry date.</p>
          </div>
          <div class="note_item">
            <p class="content">Availability may be limited depending on the entry date.Please check the availability calendar in advance.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <el-button :style="{visibility: (!sessionsShow? 'hidden' : '')}" @click="sessionsShow = false" class="back_btn" type="primary" icon="el-icon-arrow-left">Back</el-button>
      <div>
        <button class="cancel_btn" @click="closeDialog">CANCEL</button>
        <el-button @click="selectDate" :disabled="product.votes == 0" :type="product.votes > 0? 'primary':'info'">SELECT A DATE</el-button>
      </div>
    </div>
    <!-- 具体描述end -->
    <close-icon v-if="dialogVisible" @closeDialog="closeDialog" />
  </el-dialog>
</template>
<script>
import { detailJSON } from '@/assets/detailJSON/detailJSON.js'
import sessionsTable from '@/components/sessionsTable.vue'
import closeIcon from '@/components/close_icon.vue'
import { mixin2 } from '@/mixin/index'

export default {
  mixins: [mixin2],
  components: { sessionsTable, closeIcon },
  data() {
    return {
      dialogVisible: true,
      product: {},
      sessions: [], // 所有场次
      productId: 9,
      productIdMap: '', //本地数据的产品id，用于获取相应详情数据
      TimeDesignates: [],
      sessionsShow: false
    }
  },
  methods: {
    init(item) {
      // console.log('iten',item);
      if (item !== undefined) {
        this.product = item
        this.productId = item.productId
      }
      this.dialogVisible = true
      this.productDetailMap()
      this.showMapDetail()
    },
    // 获取该产品所允许进入的设施
    productDetailMap() {
      const id = this.getProductIdMap(this.productId)
      this.productIdMap = id
      detailJSON.map(item => {
        if (item.productId == id) {
          this.TimeDesignates = item.equipments
        }
      })
    },
    // 获取产品id对应的本地产品id
    getProductIdMap(id) {
      let obj = process.env.VUE_APP_PRODUCTIDMAP
      obj = eval("(" + obj + ")")
      return obj[id]
    },
    // 用逗号来分割换行
    dataHandle(data) {
      return data.split(',')
    },
    selectDate() {
      this.dialogVisible = false
      this.$emit('selectDate', this.product)
      this.$emit('footShow')
      this.$emit
    },
    // 查看场次详情
    viewSession() {
      this.sessionsShow = true
      const ele = document.getElementsByClassName('dialog_body')[0]
      ele.scrollTop = 0
    },
    closeDialog() {
      this.sessionsShow = false
      this.dialogVisible = false
       this.$emit('footShow')
       this.$emit('closeDialog')
    },
    // 控制红色那一段文本的显示
    redTxtShow() {
      return [3, 9, 10, 11, 4, 5, 2, 8, 15, 16, 19, 20, 21].includes(this.productIdMap)
    },
    // 控制小地图那部分详情的显示
    showMapDetail() {
      const curDate = new Date()
      const beginDate = new Date('2021/05/10')
      const endDate = new Date('2021/05/31')
      let flag = false
      // 设置地图部分在指定时间段内隐藏
      if (curDate <= beginDate || curDate >= endDate) {
        flag = true
      }
      return [3, 4, 19, 20, 21, 9, 10, 11, 5, 2, 8, 15, 16, 20].includes(this.productIdMap) && flag
    }
  },
}
</script>
<style lang="scss" scoped>
.notes_title {
  display: box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 10px;
  img {
    margin-right: 10px;
    margin-top: 3px;
    align-self: baseline;
  }
  span {
    font-weight: bold;
    font-size: 15px;
  }
}
.note_list {
  .note_item {
    display: box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
.content {
  padding-left: 15px;
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #d5ab81;
  }
  a {
    text-decoration: underline;
    color: #003399;
  }
}
.Time-Designated_firstTxt {
  // border-bottom: 1px dashed;
  padding: 15px 0 0;
}
.btn_box {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-bottom: 1px dashed;
  cursor: pointer;
  .select_designated_time_btn {
    border: 1px solid;
    width: 216px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 5px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(235, 235, 235, 1) 100%
    );
    font-weight: bold;
  }
}
.notes {
  .title {
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
}
.map_box {
  border: 1px solid #9fbedf;
  padding: 15px 10px;
  margin-top: 15px;
  .map_img_box {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    img {
      width: 300px;
      height: 200px;
      @media (max-width: 768px) {
        margin-top: 15px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.dialog_title {
  background-color: #003399;
  color: white;
  font-weight: bold;
  padding: 15px 10px;
  &:not(:first-child) {
    margin-top: 18px;
  }
  img {
    margin-right: 15px;
  }
}
.tip {
  line-height: 30px;
}
::v-deep .el-dialog {
  border-radius: 12px;
  max-width: 900px;
}
::v-deep .el-dialog__body {
  overflow-y: auto;
  padding: 0;
}
::v-deep .el-dialog__header {
  display: none;
}
.top_tip {
  line-height: 25px;
  p {
    font-size: 15px;
  }
}
.time_designated_box {
  border: 1px solid #9fbedf;
  padding: 20px;
  .time_designated_item {
    display: flex;
    margin-bottom: 5px;
    .label {
      background-color: #427abe;
      color: white;
      margin-right: 10px;
      align-self: baseline;
      min-width: 120px;
      padding: 0 5px;
    }
    &:last-child {
      div p {
        margin-bottom: 5px;
      }
    }
  }
}
.dialog_body {
  height: 565px;
  overflow-y: auto;
  padding: 0 20px 20px;
  margin-top: 20px;
}
.foot {
  display: flex;
  justify-content: space-between;
  background-color: #eee;
  padding: 16px 32px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 0;
  font-size: 14px;
  border-top: 1px solid rgb(167, 167, 167);
  border-radius: 0 0 12px 12px;
  .back_btn {
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #036ce2;
    background-color: inherit;
    border: none;
    padding-left: 0;
    ::v-deep .el-icon-arrow-left::before {
      font-weight: 700;
    }
  }
  .cancel_btn {
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    width: 102px;
    text-align: center;
    margin-right: 16px;
    color: #036ce2;
    border: 1px solid #036ce2;
    cursor: pointer;
    border-radius: 12px;
    background-color: white;
    box-sizing: border-box;
  }
  .select_btn {
    height: 44px;
    line-height: 44px;
    background-color: rgba(3, 108, 226, 1);
    border: 1px solid rgb(3, 108, 226);
    border-radius: 12px;
    color: white;
    font-weight: 700;
    width: 148px;
  }
  .el-button {
    border-radius: 12px;
  }
  .el-button--info {
    background-color: #c8c9cc;
    border-color: #c8c9cc;
  }
}
.back_btn_top_box {
  display: none;
  position: fixed;
  top: 0;
  // width: 100%;
  background-color: white;
  z-index: 1;
  .back_btn_top {
    padding-bottom: 8px;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #036ce2;
    background-color: inherit;
    border: none;
    padding-left: 0;
    ::v-deep .el-icon-arrow-left::before {
      font-weight: 700;
    }
  }
}
.table_content .cmn_att_time01 {
  margin-top: 10px;
}
@media (max-width: 768px) {
  /deep/ .el-dialog {
    margin-top: 0 !important;
    margin-bottom: 0;
    height: 100vh;
    border-radius: 0;
    width: 100% !important;
    .dialog_body {
      height: calc(100vh - 148px);
      margin-top: 35px;
      .dialog-body {
        height: inherit;
      }
    }
  }
  .back_btn {
    display: none;
  }
  .foot {
    padding: 16px;
    div {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      .select_btn {
        width: 100%;
      }
      .cancel_btn {
        width: 100%;
        background-color: inherit;
        border: none;
      }
    }
  }
  .back_btn_top_box {
    display: inline-block;
  }
  ::v-deep .btn_box {
    flex-wrap: wrap;
    padding-left: 0;
    .select_designated_time_btn {
      width: 290px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  /deep/.table_content .cmn_att_time01 {
    min-width: auto;
  }
}
</style>
