<template>
  <!-- 放介绍的弹框 -->
  <el-dialog :title="title" :visible.sync="confirmDialog" center class="confirmDialog" :fullscreen="screenWidth < 1250">
    <div class="subTitle" v-show="subTitle!==''">{{subTitle}}</div>
    <slot name="content" />
    <div class="subTitle" v-show="secondSubTitle!==''">{{secondSubTitle}}</div>
    <slot name="secondContent" />
    <a :href="jumpUrl" class="subTitle jumpTxt" target="_blank" v-show="jumpTxt!==''">{{jumpTxt}}</a>
    <div class="subTitle noteTxt" v-show="noteTxt!==''">{{noteTxt}}</div>
    <slot name="thirdContent" />
  </el-dialog>
</template>
<script>


export default {
  props: {
    title: { tpye: String, default: '' },
    subTitle: { tpye: String, default: '' },
    secondSubTitle: { tpye: String, default: '' },
    jumpTxt: { tpye: String, default: '' },
    jumpUrl: { tpye: String, default: '' },
    noteTxt: { tpye: String, default: '' }
  },
  data() {
    return {
      confirmDialog: false,
      screenWidth: document.body.clientWidth,
      extrasName: ""
    };
  },
  mounted() {
    const that = this;
    // 获取屏幕宽度
    window.onresize = () => {
      return (() => {
        that.screenWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    init(ticketitem, extrasName) {
      this.confirmDialog = true;
      this.ticketItem = ticketitem;
      this.extrasName = extrasName;
    },

  }
};
</script>
<style lang="scss" scoped>
// 删除票时的确认弹框
::v-deep.confirmDialog {
  .el-dialog {
    border-radius: 16px 16px 12px 12px;
    width: 1200px;
    @media (min-width: 1251px) {
      margin-top: 6vh !important;
    }
    @media (max-width: 1250px) {
      border-radius: 0 !important;
      width: 100%;
      height: 100%;
    }
  }

  .el-dialog__header {
    position: relative;
    padding: 20px 20px;
    background-color: #162b75;
    font-weight: 700;
    border-radius: 12px 12px 0 0;
    @media (max-width: 1250px) {
      padding: 10px 0;
      border-radius: 0 !important;
    }
    @media (max-width: 960px) {
      border-radius: 0;
      .el-dialog__headerbtn .el-dialog__close {
        position: absolute;
        top: -13px;
        right: -13px;
      }
    }
    span {
      color: white;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: white;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .el-dialog__body {
    @media (max-width: 1250px) {
      height: calc(100vh - 157px);
    }
    padding: 12px 12px 30px;
    font-weight: 500;
    color: #333;
    border-bottom: none !important;
  }
  .el-dialog__footer {
    padding: 20px 25px;
    .dialog-footer {
      display: flex;
    }
    button {
      width: 40%;
      line-height: 25px;
      border-radius: 10px;
      font-weight: 600;
      margin: 0;
      flex: 1;
      &:first-child {
        margin-right: 25px;
      }
    }
  }
}
::v-deep .confirmDialog {
  @media (max-width: 768px) {
    .el-dialog {
      border-radius: 0;
    }
    .el-dialog__header {
      border-radius: 0;
    }
  }
}
::v-deep .el-dialog__title {
  @media (max-width: 1250px) {
    font-size: 15px !important;
  }
}
.subTitle {
  font-weight: bold;
  font-size: 18px;
  margin: 6px 0;
  &.jumpTxt {
    color: #0368d9;
  }
  &.noteTxt {
    margin-top: 15px;
  }
}
</style>
