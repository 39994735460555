<template>
  <div class="container">
    <headTab :activetab="'2'" :subtotal="getSubTotal()" :ticketsnum="stockNum" @toShopCartFromHeadTab="toShopCart" />
    <!-- 占位div -->
    <div :style="{ height: $store.state.changeHeight + 'px' }"></div>
    <!-- 内容区start -->
    <div class="main flex-space-between">
      <!-- 内容区左边 -->
      <div style="flex:1;">
        <div class="continue_font" @click="toOptions()">
          CONTINUE<i class="font_weight_700 el-icon-arrow-right el-icon--right"></i>
        </div>
        <!-- <div style="width:100%" :style="{marginTop:(screenWidth<768?'15px' :'20px')}">
          <image-banner style="width:100%" :boxWidth="screenWidth" />
        </div> -->
        <notice :type="7" />
        <h1 class="big_title">UNIVERSAL EXPRESS PASS</h1>
        <p class="font16" style="font-weight: normal;">
          When you add Universal Express access to your theme park admission,
          you get to skip the regular lines at most of your favorite rides and
          attractions. So you can fit way more fun into your day.
        </p>
        <p class="tip font16">Separate theme park admission required.</p>
        <h2 class="title_2">Universal Express</h2>
        <div class="font14">
          <p class="font_weight_700">
            Reduce your waiting time for popular attractions!
          </p>
          <p>
            The Universal Express Pass is a convenient ticket that lets you
            enjoy each attraction once with shortened waiting times.This ticket
            can be used to shorten your waiting time at attractions and to
            guarantee a spot for viewing the parade.Tickets can be purchased on
            the day at the park, but are available only while supplies last.
            Tickets will not be available on the day if they are sold out
            beforehand (excluding some tickets).
          </p>
          <p>
            When entering the park, a separate Studio Pass (entry ticket) such
            as a 1 Day Studio Pass is required.
          </p>
          <p class="font_weight_700 mt10">Notices</p>
          <ul style="padding-left: 15px;">
            <li>Some attractions have set lengths of time.</li>
            <li>
              For safety reasons, there are established requirements for using
              the attractions. Please confirm all requirements for each
              attraction prior to purchasing tickets.
            </li>
            <li>
              <a href="https://www.usj.co.jp/web/en/us/service-guide/safe" target="_blank">Ride and height requirements for all attractions</a>
            </li>
            <li>
              Attractions may stop operations and shows may be canceled. If the
              attractions you select are not in operation, please enjoy the
              other attractions (not including time-designated attractions). For
              information regarding canceled shows, please check the detailed
              page of each Express Pass.
            </li>
            <li>
              <a href="https://www.usj.co.jp/web/en/us/park-guide/schedule/attraction-closure" target="_blank">Information of temporary closed attractions</a>
            </li>
            <li>
              There are several requirements regarding the use of Universal
              Express Passes. Please confirm the requirements of each Universal
              Express Pass prior to making a purchase.
            </li>
            <li>
              <a style="cursor: pointer" @click="openintroduce">Requirements for the Universal Express Pass</a>
            </li>
            <li>
              “Universal Express Passes” are only valid on the day obtained. (If you purchased your ticket in advance, it is only valid for the date printed on the ticket.)
            </li>
          </ul>
          <!-- <p>Face mask usage is required when experience an attraction, including in the waiting line. Guests who choose not to wear a face mask will not be able to enter or experience the attraction.</p>
          <p class="mt10">For more information about mask requirements, please ask a crew member.</p> -->
        </div>
        <!-- 票列表 -->
        <div v-for="(item, index) in dataList" :key="index">
          <div v-if="dataList.length > 0" class="mt15 tickets">
            <el-card class="box-card" style="margin-bottom: 16px;">
              <div slot="header" class="clearfix">
                <!-- 快速票 -->
                <p class="ticketType">Express Pass</p>
                <h3 class="font_weight_700 font20">
                  {{ item.officialName }}
                </h3>
                <p class="description" v-html="doBr(item.officialIntro)"></p>
                <!-- <div style="padding-top:20px;color:red" v-if="item.websiteType===4 || item.websiteType===5 || item.websiteType===6 || item.websiteType===7  || item.websiteType===8 ">Age Restriction: Guests ages 14 or under will not be admitted.</div> -->
                <div style="padding-top:20px;color:red;white-space: pre-wrap;" v-if="item.officialIntroColorText">{{ item.officialIntroColorText }}</div>
              </div>
              <div class="card-body flex-space-between">
                <el-image style="width: 1px; height: 1px;visibility:hidden;" src="" fit="fill" />
                <!-- 票计数器 -->
                <div class="counter-box">
                  <div class="flex-space-between">
                    <div>
                      <p class="font16" style="text-align:right;">
                        per ticket from
                      </p>
                      <p class="font10" style="text-align:right;">All Ages</p>
                    </div>
                    <div class="ticketPrice">
                      <p>¥{{ item.productDto.salePrice || 0 | abs }}</p>
                    </div>
                    <div class="input-number">
                      <span @click="item.votes === 0 ? '' : item.votes--" class="blueFont font24 less">-</span>
                      <span class="blueFont boldFont num">{{
                        item.votes
                      }}</span>
                      <span @click="item.votes++" class="blueFont plus">+</span>
                    </div>
                  </div>
                  <p class="ticket-disclaimer">
                    Prices per ticket vary by day, with tax.
                  </p>
                </div>
              </div>
              <div class="card-foot flex-space-between">
                <el-link class="font16" @click="viewDetail(item)" type="primary">View Details</el-link>
                <el-button @click="selectDate(item)" :disabled="item.votes == 0" :type="item.votes !== 0 ? 'primary' : 'info'">SELECT A DATE</el-button>
              </div>
            </el-card>
          </div>
        </div>
      </div>
      <!-- 侧边栏 -->
      <div id="aside" class="aside hidden">
        <div id="asideContent">
          <el-card class="box-card">
            <div @click="toShopCart()" slot="header" class="clearfix flex-space-between card-head" style="position: relative;">
              <p class="totalMoney">
                Subtotal:
                <span class="font_weight_700">¥{{ getSubTotal() || 0 | abs }}</span>
              </p>
              <span class="iconfont icon-gouwuche-copy-copy" style="position:absolute;right:8px;font-size:30px;width:46px" :style="{
                  opacity:
                  ticketsCopy.length + expressTicket.length + braceletList.length == 0 ? '.3' : ''
                }">
                <span class="ticketNum">{{ stockNum || 0 }}</span>
              </span>
            </div>
            <div class="card-body-item-base">
              <p @click="toHome()" class="flex-space-between" style="cursor: pointer;">
                <span class="ticketType">TICKETS</span>
                <i v-if="haveStudioPass" class="el-icon-circle-check" style="color:rgb(82, 164, 29);font-size:24px;" />
              </p>
              <span v-if="!haveStudioPass" class="font12">Buy Online and Save</span>
            </div>
            <div class="card-body-item-base card-body-item-active">
              <p class="flex-space-between">
                <span class="ticketType">EXPRESS PASSES</span>
                <!-- <i class="el-icon-circle-check" style="color:greenyellow;font-size:20px;" /> -->
              </p>
              <!-- express pass票列表 -->
              <div v-if="expressTicket.length > 0" @click="toExpressass">
                <transition-group>
                  <div v-for="(item, index) in expressTicket" :key="index" class="ticket-item flex-space-between">
                    <div class="item_left">
                      <p>{{ item.officialName }}</p>
                      <p class="grayFont">{{ conversionPeriod(item) }}</p>
                      <p class="grayFont">{{ item.num }} All Ages</p>
                    </div>
                    <a @click="delTicket(index, item)" class="remove_font">REMOVE</a>
                  </div>
                </transition-group>
              </div>
              <span v-else class="font12">Select Your Universal Express Pass</span>
            </div>
            <div class="card-body-item-base">
              <p @click="toOptions()" class="flex-space-between" style="cursor: pointer;">
                <span class="ticketType">Extras</span>
                <i v-if="braceletList.length > 0" class="el-icon-circle-check" style="color:rgb(82, 164, 29);font-size:24px;" />
              </p>
              <span v-if="braceletList.length <= 0" class="noTicket font12">Select Your Extras</span>
            </div>
          </el-card>
          <div class="aside-btns-box">
            <el-button @click="toHome()" icon="el-icon-arrow-left" class="blueFont font_weight_700 back_btn" style="border:none;">BACK</el-button>
            <el-button type="primary" @click="toOptions()">NEXT STEP</el-button>
            <!-- <el-button type="primary" @click="toShopCart()">NEXT STEP</el-button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 内容区end -->
    <!-- 弹框 -->
    <date-dialog v-if="dateDialogVisible" ref="dateDialog" @openDetail="viewDetail" @handleTicket="handleTicket" />
    <detail-dialog v-if="detailDialogVisible" ref="detailDialog" @selectDate="selectDate" />
    <delTicketConfirmDialog v-if="confirmDialogVisible" ref="confirmDialog" @confirm="delTicketConfirm" />
    <!-- 介绍弹框 -->
    <introduce-dialog ref="introduceDialog" title="Requirements for the Universal Express Pass" subTitle="General points of caution" secondSubTitle="Notes regarding the use of attractions" jumpTxt="Ride and height requirements for all attractions >" noteTxt="Notes" jumpUrl="https://www.usj.co.jp/web/en/us/service-guide/safe">
      <template slot="content">
        <ul class="introduceUlList">
          <li>“Universal Express Passes” are only valid on the day obtained. (If you purchased your ticket in advance, it is only valid for the date printed on the ticket.)</li>
          <li>Universal Express Passes are only valid for the purchaser. (You will not be able to share the ticket with a companion.)</li>
          <li>Universal Express Passes are valid for the attractions designated by each pass. For passes with multiple attractions printed on them, it is only valid for one of the printed attractions.</li>
          <li>Some attractions may take time to access due to seat number limits, capacity limits, and set time lengths. Also, it may take time to access attractions on crowded days during peak hours for using Universal Express Passes.</li>
          <li>Refunds will not be offered if you arrive late to the attraction, and you will not be able to change to a different time slot.</li>
          <li>This ticket cannot be used if you entered the park with a Twilight Pass (entry after 3:00 PM). If you purchase a time-designated Universal Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. Please choose your times carefully when purchasing a ticket.</li>
          <li>Once purchased, tickets cannot be canceled.</li>
          <li>There is a limited number of available Universal Express Passes in a day for each time slot. Also, sales may be suspended due to unforeseen circumstances.</li>
          <li>When entering the park, a separate Studio Pass (entry tickets such as a 1 Day Studio Pass) is required.</li>
          <li>Some attractions are not in operation during certain times. Please be sure to confirm in advance. Click here for details.</li>
          <li>Sesame Street 4-D Movie Magic? and Shrek’s 4-D Adventure will be shown in the same theater. Showing times are different for each, so please be sure to check the Show Schedule. Also, you will only be able to choose one of the two shows.</li>
          <li>Do not bend or wet the QR code portion of the ticket.</li>
        </ul>
      </template>
      <template slot="secondContent">
        <ul class="introduceUlList">
          <li>In order to ensure the safety of guests, certain requirements regarding height and health conditions must be met to use the attractions included in the Universal Express Pass. Please confirm all requirements for each attraction prior to purchasing tickets.</li>
        </ul>
      </template>
      <template slot="thirdContent">
        <ul class="introduceUlList">
          <li>Even if you have purchased a Universal Express Pass, you will not be able to use the attractions if you do not meet the height requirements.</li>
          <li>If you are unsure about your height, please consult with a member of the crew at the entrance of each attraction. After confirming your height, you will be given a handstamp.</li>
          <li>For your safety, please refrain from using the attraction if you are pregnant.</li>
        </ul>
      </template>
    </introduce-dialog>
  </div>
</template>

<script>
import dateDialog from "./date-dialog.vue";
import headTab from "@/components/headTab";
import introduceDialog from "@/components/introduceDialog";
import detailDialog from "./detail-dialog.vue";
import { getFastTicket } from "@/api/index";
import { formatDate, getScrollTop, judgeMobile } from "@/utils/util";
import { mixin1, mixin2 } from "@/mixin/index";
import { descriptions } from "@/assets/detailJSON/product_description.js";
import delTicketConfirmDialog from "../../components/delTicketConfirmDialog.vue";
import notice from "@/components/notice";
import ImageBanner from '../../components/imageBanner_top.vue';
let arr = [];
let fd = [];
export default {
  mixins: [mixin1, mixin2],
  components: {
    dateDialog,
    headTab,
    detailDialog,
    delTicketConfirmDialog,
    introduceDialog,
    notice,
    ImageBanner
  },
  data() {
    return {
      dataList: [],
      dateDialogVisible: false,
      detailDialogVisible: false,
      asideTickets: [],
      orderInfo: {},
      haveStudioPass: false,
      haveFastTickets: false,
      haveBracelet: false,
      braceletList: [],
      expressTicket: [],
      tickets: [],
      confirmDialogVisible: false,
      ticketIndex: 0, // 侧边栏被删除票的idnex
      delTicketItem: {}, // 被删除的票
      delTicketIndex: "", // 删除的票的index
      relatBraceletList: [], // 与被删除的快速券有关的手环产品
      delTicketId: "", // 被删除的快速券的id
      delTicketDate: '', //被删除的快速券的日期
      stockNum: 0,

    };
  },
  mounted() {
    var asideContent = document.querySelector("#asideContent");
    asideContent.style.top = 40 + "px";
    window.addEventListener("scroll", this.handleScroll);
    this.getData();
    this.handleTicket();
    this.dataHandle();
    if (JSON.parse(localStorage.getItem("orderInfo")) !== null) {
      JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList.forEach(item => {
        if (item.ticketKind === 3) {
          this.expressTicket.push(item)
        }
        else if (item.ticketKind === 8) {
          this.braceletList.push(item)
        }
      })
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取快速票
    async getData() {
      //  获取快速票7
      await getFastTicket({ ticketKind: 3 }).then(res => {
        if (res.data.code === 0) {
          res.data.data.list.forEach(item => {
            item.votes = 0;
          });
          this.dataList = res.data.data.list;
        }
      });
    },
    // 换行处理
    doBr(val) {
      if (val) {
        return val.replace(/\n/g, '<br>')
      }
      return ''
    },
    // 获取本地数据
    handleTicket(val) {
      if (JSON.parse(localStorage.getItem("orderInfo"))) {
        this.orderInfo = JSON.parse(localStorage.getItem("orderInfo"));
        this.asideTickets = this.orderInfo.orderRequestProductList;
        this.tickets = this.orderInfo.orderRequestProductList;
        this.expressTicket = []; // 包含快速券和手环,因为手环跟快速券差不多
        const studioTicketType = [1, 2, 5, 7, 9]; // 一日两日票的ticketKind
        for (let i = 0; i < this.asideTickets.length; i++) {
          if (studioTicketType.includes(this.asideTickets[i].ticketKind)) {
            this.haveStudioPass = true;
          } else if (this.asideTickets[i].ticketKind === 3) {
            this.haveFastTickets = true;
          }
        }
        setTimeout(() => {
          if (val) {
            this.$router.go(0);
          }
        }, 50);
      }
    },
    // 获取产品id对应的本地产品id
    // getProductIdMap(id) {
    //   let obj = process.env.VUE_APP_PRODUCTIDMAP
    //   obj = eval("("+obj+")")
    //   return obj[id]
    // },
    // 根据返回的产品id获取对应的产品描述
    descriptionMap(productId) {
      let id = this.getProductIdMap(productId);
      let description = "";
      descriptions.map(item => {
        if (item.productId == id) {
          description = item.description;
        }
      });
      return description;
    },
    // 获取总价
    getSubTotal() {
      let total = 0;
      let expressTicketArr = []
      let onetwoArr = []
      let onetwolength = localStorage.getItem('oneTwoLength') // 更新本地1日，2日，1.5日 数组长度
      if (JSON.parse(localStorage.getItem("orderInfo"))) {
        JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList.forEach(item => {
          if (item.ticketKind === 1 || item.ticketKind === 2 || item.ticketKind === 5 || item.ticketKind === 7 || item.ticketKind === 9) {
            onetwoArr.push(item)
          }
          else if (item.ticketKind === 3) {
            expressTicketArr.push(item); //提取出快速票
          }
        })
      }
      if (onetwoArr.length > 0) {
        onetwoArr.map(item => {
          total += item.num * item.price;
        });
      }
      if (this.expressTicket.length > 0) {
        this.expressTicket.map(item => {
          total += item.num * item.price;
        });
      }
      if (this.braceletList.length > 0) {
        this.braceletList.map(item => {
          total += item.num * item.price;
        });
      }

      this.stockNum = parseInt(onetwolength) + parseInt(expressTicketArr.length) + parseInt(this.braceletList.length)
      return total;
    },
    // 查看详情
    viewDetail(item) {
      if (judgeMobile() === undefined) {
        this.detailDialogVisible = true;
        this.$nextTick(() => {
          this.$refs.detailDialog.init(item);
        });
      } else {
        this.$router.push({ path: "/expressPassDetaildialog", query: { item: encodeURI(JSON.stringify(item)) } });
      }
    
    },
    // 打开时间弹框
    selectDate(item) {
      this.$store.commit("SET_SCROLLTOP", getScrollTop());
      if (judgeMobile() === undefined) {
        this.dateDialogVisible = true;
        this.$nextTick(() => {
          this.$refs.dateDialog.init(item);
        });
      } else {
        this.$router.push({ path: "/expressPassDateDialog", query: { item: encodeURI(JSON.stringify(item)) } });
      }
    },
    toHome() {
      this.$router.push({ path: "/" });
    },
    // 快速票页面
    toExpressass() {
      this.$router.push({ path: "/expressPass" });
    },
    // 删除票
    delTicket(index, item) {
      // 相符的次数 票id
      let Correspondingnumber = 0;
      // 相符的次数 票 日期 
      let CorrespondingDatenumber = 0;
      // 相对应的手环名字
      let Correspondingname = "";
      // 相对应的手环日期
      let CorrespondingDate = "";
      //  记录删除指定快速券的日期
      this.delTicketDate = item.travelDate
      let delAfterArr1 = []
      let oneTwoArr = [] //1日,1.5日,2日数组
      // 删除有关手环产品
      // 获取一日券,二日券,1.5日券
      if (JSON.parse(localStorage.getItem("orderInfo")) !== null) {
        JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList.forEach(item => {
          if (item.ticketKind === 1 || item.ticketKind === 2 || item.ticketKind === 5 || item.ticketKind === 7 || item.ticketKind === 9) {
            oneTwoArr.push(item)
          }
        })
      }
      // 如果购物车有手环，判断是否跟快速券有关
      if (this.braceletList.length > 0) {
        const relatedBraceletProduct = this.braceletList.some(ticket => {
          return (
            ticket.ticketKind === 6 &&
            item.travelDate === ticket.travelDate &&
            ticket.expressTicketIds.includes(item.id.toString())
          );
        });
        // 循环遍历手环绑定快速券的id，在购物车中是否有多张相符的快速券
        this.expressTicket.forEach(item1 => {
          this.braceletList.forEach(item2 => {
            if (item2.expressTicketIds) {
              item2.expressTicketIds.forEach(item3 => {
                Correspondingname = item2.officialName; // 找到手环名字
                CorrespondingDate = item2.travelDate // 找到手环日期
                // 每找到一张相符的快速券+1
                if (item.id.toString() === item3) {
                  Correspondingnumber++ //如果手环绑定快速券的id相符+1
                }
              });
            }
          });
        });
        this.expressTicket.forEach(ticket1 => {
          if (item.travelDate === ticket1.travelDate) {
            CorrespondingDatenumber++
          }
        })
        // 如果有包含该快速券的手环产品，弹框提示
        if (relatedBraceletProduct && Correspondingnumber === 1) {
          this.confirmDialogVisible = true;
          this.delTicketIndex = index; // 记录被删除快速券的index
          this.delTicketId = this.expressTicket[index].id; // 被删除的快速券的id
          this.$nextTick(() => {
            this.$refs.confirmDialog.init(item, Correspondingname);
          });
        } else if (relatedBraceletProduct && CorrespondingDatenumber === 1) {
          this.confirmDialogVisible = true;
          this.delTicketIndex = index; // 记录被删除快速券的index
          this.delTicketId = this.expressTicket[index].id; // 被删除的快速券的id
          // this.delTicketIndex = 
          this.$nextTick(() => {
            this.$refs.confirmDialog.init(item, Correspondingname);
          });
        }
        else {
          // 否则直接删除快速
          this.expressTicket.splice(index, 1);
          delAfterArr1 = delAfterArr1.concat(oneTwoArr, this.expressTicket, this.braceletList)
          this.orderInfo.orderRequestProductList = [];
          this.orderInfo.orderRequestProductList = delAfterArr1;
          localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
          this.$store.commit("SET_SHOPCART"); // 更新vuex的数据
          this.$store.commit("SET_TICKET_DATE"); // 更新票的可选时间
          this.handleTicket();
          this.$router.go(0);
        }
      } else {
        // 否则直接删除快速
        this.expressTicket.splice(index, 1);
        delAfterArr1 = delAfterArr1.concat(oneTwoArr, this.expressTicket, this.braceletList)
        this.orderInfo.orderRequestProductList = [];
        this.orderInfo.orderRequestProductList = delAfterArr1;
        localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
        this.$store.commit("SET_SHOPCART"); // 更新vuex的数据
        this.$store.commit("SET_TICKET_DATE"); // 更新票的可选时间
        this.handleTicket();
        this.$router.go(0);
      }
    },
    // 弹框确认事件
    delTicketConfirm() {
      this.confirmDialogVisible = false;
      this.expressTicket.splice(this.delTicketIndex, 1); // 删除快速券
      let delAfterArr = [] //删除后合并的数组
      let oneTwoArr = [] //1日,1.5日,2日数组
      // 删除有关手环产品
      // 获取一日券,二日券,1.5日券
      if (JSON.parse(localStorage.getItem("orderInfo")) !== null) {
        JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList.forEach(item => {
          if (item.ticketKind === 1 || item.ticketKind === 2 || item.ticketKind === 5 || item.ticketKind === 7 || item.ticketKind === 9) {
            oneTwoArr.push(item)
          }
        })
      }
      // 循环手环列表找到指定的快速券id和删除日期,并且删除对应的快速券和手环
      this.braceletList.forEach((item, index) => {
        item.expressTicketIds.forEach(item1 => {
          if (item.travelDate === this.delTicketDate && item1 === this.delTicketId.toString()) {
            this.braceletList.splice(index, 1);
          }
        })
      })
      delAfterArr = delAfterArr.concat(oneTwoArr, this.expressTicket, this.braceletList)
      this.orderInfo.orderRequestProductList = [];
      this.orderInfo.orderRequestProductList = delAfterArr;
      localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
      this.$store.commit("SET_SHOPCART"); // 更新vuex的数据
      this.$store.commit("SET_TICKET_DATE"); // 更新票的可选时间
      this.handleTicket();
      this.$router.go(0);
    },
    // 设置有限期范围
    conversionPeriod(item) {
      var startTime = item.travelDate;
      startTime = formatDate(startTime);
      return `${startTime}`;
    },
    toShopCart() {
      this.$router.push({ path: "/shopCart" });
    },
    toOptions() {
      this.$router.push({ path: "/options" });
    },
    toExpressPass() {
      this.$router.push({ path: "/expressPass" });
    },
    openintroduce() {
      this.$nextTick(() => {
        this.$refs.introduceDialog.init()
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  .main {
    width: 1200px;
    margin: 0 auto;
    .aside {
      margin-left: 30px;
      #asideContent {
        position: relative;
        top: 0;
        width: 240px;
      }
      .card-body-item-base {
        padding: 16px;
        padding-bottom: 24px;
        max-height: 300px;
        overflow: auto;
        &:not(:last-child) {
          border-bottom: 1px solid #ddd;
        }
      }
      .card-body-item-active {
        border-left: 2px solid #0959c2;
        background-color: #f9fcff;
      }
    }
  }
}
.big_title {
  margin: 19.92px 0;
  font-size: 24px;
  line-height: 1.25;
  font-weight: 900;
  color: #333333;
}
.continue_font {
  display: none;
}
::v-deep.aside-btns-box {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  .back_btn {
    padding: 0;
    margin-right: 30px;
    span {
      vertical-align: middle;
    }
  }
  .el-button {
    font-size: 12px;
  }
  .el-button--primary {
    border-radius: 12px;
    font-weight: 700;
    height: 31px;
    line-height: 31px;
    padding: 0 15px;
    background-color: #036ce2;
    margin-left: 0;
  }
}
.title_2 {
  font-size: 20px;
  line-height: 1.25;
  font-weight: 900;
  margin: 0;
  color: #333;
  margin-bottom: 5px;
}
.tip {
  padding: 15px;
  background: -webkit-linear-gradient(to bottom, #1d3586, #3555a3);
  background: -o-linear-gradient(to bottom, #1d3586, #3555a3);
  background: -moz-linear-gradient(to bottom, #1d3586, #3555a3);
  background: linear-gradient(to bottom, #1d3586, #3555a3);
  color: white;
  margin: 15px 0 20px 0;
  font-weight: 500;
}
.card-foot {
  border-top: 1px solid gainsboro;
  padding: 24px 24px;
  .el-button {
    border-radius: 12px;
    font-weight: 700;
  }
  .el-link {
    font-weight: 500;
    color: rgb(3, 108, 226);
  }
  .el-button--primary {
    background-color: #036ce2;
  }
}
.ticket-img {
  width: 113px;
  height: 75px;
}
.counter-box {
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  margin-top: 15px;
  .ticketPrice {
    margin-left: 16px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 700;
  }
}
.totalMoney {
  // margin-right: 20px;
  vertical-align: super;
  font-weight: 400;
  font-size: 16px;
}
.aside {
  .ticketType {
    cursor: pointer;
    font-size: 12px;
    line-height: 1.25;
    font-weight: 500;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }
}
.card-head {
  align-items: center;
  .ticketNum {
    position: absolute;
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
    background-color: rgb(22, 43, 117);
    color: white;
    text-align: center;
    font-size: 12px;
    top: 6px;
    right: 15px;
  }
}
.ticket-disclaimer {
  text-align: right;
  margin-top: 8px;
  margin-bottom: 35px;
  font-size: 10px;
}
/**票计数器start**/
.input-number {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 24px;
  margin-left: 25px;
  margin-top: -10px;
  display: flex;
  align-items: center;
  .num {
    width: 40px;
    display: inline-block;
    text-align: center;
  }
  .plus {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid gainsboro;
    line-height: 19px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
  }
  .less {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid gainsboro;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
  }
}
/**票计数器end**/
.el-card {
  border-radius: 15px;
}
/* 删除产品动画过度start*/
.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.v-enter-active,
.v-leave-active {
  transition: all 1s ease;
}
.ticket-item {
  font-size: 12px;
  font-weight: 500;
  margin-top: 16px;
  color: #333333;
  .item_left {
    width: 132.47px;
  }
  .remove_font {
    cursor: pointer;
    font-size: 10px;
    font-weight: 500;
    color: #036ce2;
    min-width: 55px;
  }
}
.main-head {
  padding: 15px;
  align-items: center;
}
::v-deep .aside-btns-box [class^="el-icon-"] {
  font-size: 24px;
  vertical-align: middle;
  font-weight: 700;
}
/* 删除产品动画过度end*/
// 删除票时的确认弹框
::v-deep.confirmDialog {
  .el-dialog {
    border-radius: 16px 16px 12px 12px;
  }
  .el-dialog__header {
    padding: 20px 20px;
    background-color: #162b75;
    font-weight: 700;
    border-radius: 12px 12px 0 0;
    span {
      color: white;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: white;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .el-dialog__body {
    @media (max-width: 768px) {
      height: calc(100vh - 157px);
    }
    font-weight: 500;
    color: #333;
    border-bottom: 1px solid #f2f2f2;
  }
  .el-dialog__footer {
    padding-top: 20px;
    button {
      width: 40%;
      line-height: 25px;
      border-radius: 10px;
      font-weight: 600;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
::v-deep .confirmDialog {
  @media (max-width: 768px) {
    .el-dialog {
      border-radius: 0;
    }
    .el-dialog__header {
      border-radius: 0;
    }
  }
}
@media (max-width: 1200px) {
  .container {
    width: 100%;
    .main {
      width: 100%;
      padding: 0 0.9375rem;
      box-sizing: border-box;
    }
  }
  .counter-box {
    .ticketPrice {
      margin-left: 8px;
      margin-right: 10px;
    }
  }
  .input-number {
    margin-left: 0;
  }
}
@media (max-width: 780px) {
  .hidden {
    display: none;
  }
  .container .main {
    padding-top: 120px;
  }
  .continue_font {
    font-size: 12px;
    display: block;
    text-align: right;
    color: #036ce2;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
  }
  .card-foot {
    display: flex;
    flex-direction: column-reverse;
    padding: 16px 16px;
    .el-link {
      padding-top: 16px;
    }
  }
  ::v-deep .el-icon-arrow-right:before {
    font-size: 24px;
    vertical-align: middle;
  }
  .ticket-disclaimer {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .input-number {
    min-width: 88px;
  }
}
::v-deep .tickets .el-card__body {
  padding: 0;
}
::v-deep .el-card__header {
  border-bottom: none;
}
::v-deep .el-icon-shopping-cart-2:before {
  font-size: 30px;
}
::v-deep .tickets .el-card__header {
  padding-top: 32px;
  padding-left: 24px;
  padding-right: 24px;
}
.tickets {
  .ticketType {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .description {
    font-size: 16px;
    line-height: 1.5;
    font-weight: normal;
    color: #333;
    padding: 0;
    text-align: left;
  }
}
::v-deep .aside .el-card__header {
  background-color: #036ce2;
  color: white;
  padding: 10px 8px 10px 16px;
}
::v-deep .aside .el-card {
  max-width: 300px;
}
::v-deep .aside .el-card__body {
  padding: 0;
  // padding-bottom: 15px;
}
::v-deep .el-link.is-underline:hover:after {
  border-bottom: none;
}
ul a {
  color: #003399;
  text-decoration: underline;
}
.introduceUlList {
  padding: 12px 22px;
  font-size: 15px;
  letter-spacing: 0.5px;
}
</style>
